/* globals fetch */
import { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { push } from 'gatsby';

import MasterLayout from '../components/MasterLayout/MasterLayout';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.target;

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state
      })
    })
      .then(() => push(form.getAttribute('action')))
      .catch(error => window.alert(error));
  }

  render() {
    const { location } = this.props;

    return (
      <MasterLayout location={location}>
        <Helmet>
          <title>Contact - Nupateer</title>
        </Helmet>

        <h1>Contact</h1>

        <p>
          If you have any questions, please use the contact form below or email{' '}
          <a href="mailto:contact@nupateer.com">contact@nupateer.com</a>.
        </p>

        <p>
          For all media enquiries please email{' '}
          <a href="mailto:contact@nupateer.com">contact@nupateer.com</a>.
        </p>

        <form
          name="contact"
          method="post"
          action="/thanks/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />

          <p hidden>
            <label>
              Don’t fill this out:{' '}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </p>

          <p>
            <label>
              Name:
              <br />
              <input type="text" name="name" onChange={this.handleChange} />
            </label>
          </p>

          <p>
            <label>
              Email address:
              <br />
              <input type="email" name="email" onChange={this.handleChange} />
            </label>
          </p>

          <p>
            <label>
              Phone number:
              <br />
              <input type="text" name="phone" onChange={this.handleChange} />
            </label>
          </p>

          <p>
            <label>
              Message:
              <br />
              <textarea name="message" onChange={this.handleChange} />
            </label>
          </p>

          <p>
            <button type="submit">Send</button>
          </p>
        </form>
      </MasterLayout>
    );
  }
}

ContactPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default ContactPage;
